import '../App.css';
import React from 'react';
import NavBar from '../components/NavBar';
import { Circles } from '../components/Circles';
import Footer from '../components/Footer';

export default function ContactPage() {

  return (
    <div className='App'>
      <NavBar/>
      <div className='window'>
        <Circles/>
        <h1 className='headerTitle'>Contact me</h1>
        <div className="aboutme">
          <div className="aboutmeRight">
            <img src='./Me.jpeg' alt='hello'/>
          </div>
          <div className="aboutmeLeft">
            <h2>Aamuel Chua</h2>
            <h4>Hello, I am extremely humbled by you visting my website, if you would like to contact me, do reach out to me via the following ways</h4>
            <div className="pageButtonContainer">
              <a href="mailto:aamuelchua@icloud.com"><button className="pageButton">Email</button></a>
              <a href="https://www.linkedin.com/in/aamuelchua/"><button className="pageButton">LinkedIn</button></a>
              <a href="https://www.instagram.com/aamuelchua/"><button className="pageButton">Instagram</button></a>
              <a href="https://github.com/aamuelchua"><button className="pageButton">GitHub</button></a>
              <a href="resume.pdf" download><button className="pageButton">Résumé</button></a>
            </div>

            
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}