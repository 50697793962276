import './App.css';
import React from 'react';
import { Routes, Route } from "react-router-dom";
import HomePage from './screen/HomePage';
import ProjectPage from './screen/ProjectPage';
import ExperiencePage from './screen/ExperiencePage';
import ContactPage from './screen/ContactPage';
import My404 from './screen/My404';

function App() {

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/project" element={<ProjectPage />} />
        <Route path="/experience" element={<ExperiencePage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path='*' exact={true} component={<My404/>} />
      </Routes>
    </div>
  );
}

export default App;
