import '../App.css';
import React from 'react';
import NavBar from '../components/NavBar';
import { Circles } from '../components/Circles';
import Footer from '../components/Footer';

export default function My404() {

  return (
    <div className='App'>
      <NavBar/>
      <div className='window'>
        <Circles/>
        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
          <h1 className='headerTitle'>404 Error</h1>
          <h2 className='headerTitle' style={{color:'gray'}}>Sorry, I am unable to index your page :( Please Try again</h2>
        </div>
      </div>
      <Footer/>
    </div>
  );
}