import '../App.css';
import React from 'react';
import NavBar from '../components/NavBar';
import { Link } from 'react-router-dom';
import { Circles } from '../components/Circles';
import { experience,education } from '../portfolio';
import Footer from '../components/Footer';

export default function ExperiencePage() {

  return (
    <div className='App'>
      <NavBar/>
      <div className="window">
        <Circles/>
        <h1 className='headerTitle'>Experience</h1>
        <h3 className='subtitle'>Work Experience</h3>
        <div className='experienceFlatList'>
          {experience.map(experience_data=>{
            return(
              <Link className={'indyExperienceItem'} to={experience_data.link}>
                <div className="experienceBox">
                  <img src={experience_data.image} alt="" />
                  <div className={'experienceParagraph'}>
                    <h2 className="title">{experience_data.company}</h2>
                    <p>{experience_data.description}</p>
                  </div>
                  <div className="innerExperienceBox"> 
                    <h4 className='position' style={{fontSize:12,color:'gray'}}>Positions held</h4>
                    {experience_data.position.map(pos=>{
                      return(
                        <div className={'regin'}>
                          <h4 style={{fontSize:22,paddingBottom:0}}>{pos.job_title}</h4>
                          <h5 style={{color:'gray',marginTop:0,paddingTop:0}}>{pos.since} to {pos.end}</h5>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
        <h3 className='subtitle'>Education Life</h3>
        <div className='experienceFlatList'>
          {education.map(experience_data=>{
              return(
                <Link className={'indyExperienceItem'} to={'/'}>
                  <div className="experienceBox">
                    <img src={experience_data.image_path} alt="" />
                    <div className={'experienceParagraph'}>
                      <h2 className="title">{experience_data.school}</h2>
                      <p>{experience_data.description}</p>
                    </div>
                    <div className="innerExperienceBox"> 
                      <h4 className='position' style={{fontSize:12,color:'gray'}}>Commitments</h4>
                      {experience_data.commitment.map(pos=>{
                        return(
                          <div className={'regin'}>
                            <h4 style={{fontSize:22,paddingBottom:0}}>{pos.role}</h4>
                            <h5 style={{color:'gray',marginTop:0,paddingTop:0}}>{pos.period}</h5>
                          </div>
                        )
                      })}
                      <h4 className='position' style={{fontSize:12,color:'gray'}}>Results</h4>
                      {experience_data.result.map(pos=>{
                        return(
                            <h5 style={{marginTop:0,paddingTop:0}}>{pos}</h5>
                        )
                      })}
                    </div>
                  </div>
                </Link>
              )
            })}
        </div>
      </div>
      <Footer/>
    </div>
  );
}