import '../App.css';
import React from 'react';
import { Link } from 'react-router-dom';
import { Circles } from '../components/Circles';

export default function HomePage() {

  return (
    <div className="App" style={{height:'100%',justifyContent:'center',alignItems:'center'}}>
      {/* {showNavBar?<NavBar/>:null} */}
      <div className="window" style={{marginTop:window.innerWidth>800?100:0}}>
        <Circles/>
        <div className="aboutme">
          <div className="aboutmeRight">
            <img src='./Me.jpeg' alt='hello'/>
          </div>
          <div className="aboutmeLeft">
            <h2>Aamuel Chua</h2>
            <h3>My name is Aamuel Chua and I am a software developer and an aviation enthusiast</h3>
            <div className="pageButtonContainer">
              <Link to={'/experience'}><button className="pageButton">Experience</button></Link>
              <Link to={'/project'}><button className="pageButton">My Projects</button></Link>
              <Link to={'/contact'}><button className="pageButton">Contact Me</button></Link>
            </div>
          </div>
          
        </div>
        
       
      </div>
      
    </div>
  );
}
