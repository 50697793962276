export const projects = [
    {
        location:'personal',
        project_title:'Personal Website',
        image_link:'../img/personal.png',
        goto_link:'/',
        description:'A portfolio site to showcase my life journey',
        tags:'#React #WebDev'
    },
    {
        location:'personal',
        project_title:'Tower Bot',
        image_link:'../img/AviationNews.png',
        goto_link:'/',
        description:'A Discord bot that scrapes a website for aviation news and publish to a specified channel',
        tags:'#Python #Webscrapping #Discordbot'
    },
    {
        location:'personal',
        project_title:'Email Spam',
        image_link:'../img/email.png',
        goto_link:'/',
        description:'Created a email bot to learn how mass emails are sent',
        tags:'#Python #EmailSpamBot #Email'
    },
    {
        location:'personal',
        project_title:'Waymaker - Video Edit',
        image_link:'../img/waymaker.png',
        goto_link:'/',
        description:'During the 2020 pandemic lockdown, video edited a church song called Waymaker by Michael W Smith.',
        tags:'#PremierePro #VideoEditing'
    },
    {
        location:'ocl',
        project_title:"Learning Management System",
        image_link:'../img/lms.png',
        goto_link:'/',
        description:'An LMS that I have built for OurCodeLab. It is a full stack application that allows users to create courses, enroll in courses and take quizzes. It also allows users to create their own courses and sell it to other users',
        tags:'#React #NodeExpress #MySQL'
    },

    {
        location:'ocl',
        project_title:'Aviation Management',
        image_link:'../img/plane.png',
        goto_link:'/',
        description:'My first mobile application developed for OurCodeLab. Released in 2020, received over 1,000 paid downloads on both Apple App Store and Google Play Store',
        tags:'#LiveCode #AviationApp #MobileApp'
    },
    {
        location:'ocl',
        project_title:'Impossible Cookie',
        image_link:'../img/cookie.png',
        goto_link:'/',
        description:'Built a full stack application with LiveCode and Python Socket. A simple cookie game for the modern era, received over hundreds of downloads',
        tags:'#LiveCode #PythonFlask #Notability'
    },
    {
        location:'ocl',
        project_title:'Gelato Park - Loyalty Programme',
        image_link:'../img/gelato.png',
        goto_link:'/',
        description:'Started researching on React Native in 2022, converted an old application into a Full Stack Loyalty Programme Application',
        tags:'#ReactNative #NodeExpress #MongoDB'
    },

    {
        location:'ocl',
        project_title:'Experia - Event Experience App',
        image_link:'../img/experia.png',
        goto_link:'/',
        description:'I was interested in Augmented Reality and 3D Modeling, went on and built a Event Experience App with ReactViro. Using that framework, I was able to showcase multiple 3D models and 3D Images as well as 360º Videos',
        tags:'#ReactNative #AugmentedReality'
    },

    {
        location:'ocl',
        project_title:'Remly - POS System',
        image_link:'../img/remly.png',
        goto_link:'/',
        description:'POS System has been my dream application to build as it comprises of POS Ordering system, Loyalty Programme, Booking Management System, Customer Resource Management and Unit Inventory System',
        tags:'#ReactNative #NodeExpress #MongoDB #SocketAndHTTP'
    },

    {
        location:'empire',
        project_title:'Science Immersive VR',
        image_link:'../img/oculus.jpg',
        goto_link:'/',
        description:'I was in charge of the inital foundation of a Oculus VR Project in Empire Code Launchpad. Subsequently led a team of interns to complete the project for Empire Code Education',
        tags:'#UnityGameEngine #C# #VirtualReality #Oculus'
    },
    {
        location:'empire',
        project_title:'React Native - Curriculum',
        image_link:'../img/react.png',
        goto_link:'/',
        description:'I have drafted out and researched on a Mobile Application Curriculum for Empire Code Education. In total there is about 70 hours worth of teaching curriculum for the trainers at Empire Code.',
        tags:'#ReactNative #Teaching #AppMaking'
    },
    {
        location:'empire',
        project_title:'Lego Stop Motion - Curriculum',
        image_link:'../img/waymaker.png',
        goto_link:'/',
        description:'I have created the Lego Stop Motion curriculum and run a Lego Stop Motion Camp from 20 June to 23 June in Empire Code Education. ',
        tags:'#LegoStopMotion #Animation #Camp'
    },
]

export const experience = [

    {
        company:'OurCodeLab',
        image:'../img/ocl.png',
        link:'https://www.ourcodelab.com/',
        position:[
            {
                job_title: 'Chief Operating Officer',
                since: "October 2022",
                end:'Present',
            },
            {
                job_title: 'Co-Founder',
                since: "August 2017",
                end:'September 2021',
            },
        ],
        description:"OurCodeLab is my personal software company. I have founded it along side Gabriel Seet as my partner. We want to make useful software easily accessible by all. To achieve this, we placed our work into the hands of our clients to help with their business. To date, we have released various inexpensive, yet efficient products such as a Point Of Sale System, Loyalty Rewards Application, Web-App Portal etc"
    },
    {
        company:'Empire Code',
        image:'../img/image-3.jpg',
        link:'https://www.empirecode.co/',
        position:[
            {
                job_title: 'Freelance Software Developer and Programming Trainer',
                since: "May 2022",
                end:'Present',
            },
            {
                job_title: 'Programmer Intern @ Launchpad',
                since: "May 2022",
                end:'August 2022',
            },
            {
                job_title: 'Programming Trainer Intern',
                since: "April 2022",
                end:'August 2022',
            },
            {
                job_title: 'Freelance Programmer Trainer',
                since: "November 2021",
                end:'March 2022',
            },
        ],
        description:"Empire Code is paving the way for future computer engineers and programmers. It is a enrichment STEM centre which teaches their students robotics, programming, science and mathematics. They have a software development branch called Launchpad whereby they build software solutions for clients."
    },
    {
        company:'SingPost',
        image:'../img/image-2.jpg',
        link:'https://www.singpost.com/',
        position:[
            {
                job_title: 'Warehouse Picker Packer',
                since: "March 2021",
                end:'April 2021',
            },
        ],
        description:"My time at Singpost was really sightful as it taught me determination and grit. It was a overnight job and it allows me to have a further insight to see how mail and parcels are transported first hand in the aviation industry. Through the part time work, I managed to gain skills in learning how to use their inventory system as well."
    },
    {
        company:'Genki Sushi',
        image:'../img/image.png',
        link:'https://www.genkisushi.com.sg/',
        position:[
            {
                job_title: 'Service and Kitchen Crew',
                since: "January 2020",
                end:'April 2020',
            },
        ],
        description:"Working at Genki Sushi is a eye-opening experience as I faced many different customers everyday. I am expected to work fast and efficiently. I have the chance to use their Point of Sales system which made me wanting to create my own POS System."
    },
    
]

export const education = [
    {
        school: 'Temasek Polytechnic',
        description:'I am a Year 3 Student currently pursuing my diploma in Aviation Management. The Aviation Industry is a complex industry that keeps the world going. I would like to be part of this industry to make life even better for others.',
        image_path:'../img/poly.png',
        commitment:[
            {
                role:'Treasurer of BlackBox, a Diploma Club',
                period:'2020-2021'
            },
            {
                role:'Recreational Frisbee Member',
                period:'2020-2021'
            },
            {
                role:'Enginnering Club Member',
                period:'2020-present'
            }
        ],
        result:[
            'Cumulative GPA: 3.81',
            'Year 1 Semester 1: 3.90',
            'Year 1 Semester 2: 3.87',
            'Year 2 Semester 1: 3.88',
            'Year 2 Semester 2: 3.56',
            'Year 3 Semester 1: 4.00',
        ]

    },
    {
        school: 'Montfort Secondary School',
        description:'I enrolled into Montfort Secondary School right after my Primary School Leaving Examinations (PSLE). During my 4 years of education in a Boy Schools, I picked up my love for programming and aviation. I have learnt to be disciplined and to be well mannered with everyone I come across with. Being a student in Montfort Secondary also taught me the meaning of team spirit and brotherhood. The times in school is not an easy challenge. We will need to work together as a team to finish the race.',
        image_path:'../img/secsch.png',
        commitment:[
            {
                role:'Co-President of Infocomm Club (Programming)',
                period:'2017-2019'
            },
            {
                role:'School Cyberwellness Ambassador',
                period:'2017-2018'
            },
        ],
        result:[
            'O Level Distinction in:',
            '1. Pure Physics',
            '2. Additional Mathematics',
            '3. Elementary Mathematics',
            '4. Computing',
        ]

    }
]