import '../App.css';
import React from 'react';

export const Circles = () =>{
    return(
        <div className="threecircles">
          <div className="circle1"></div>
          <div className="circle2"></div>
          <div className="circle3"></div>
        </div>
    )
}