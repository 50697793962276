import React from 'react';
import { Link } from 'react-router-dom';
import '../App.css';


const Footer = () =>{

    return(
        <div className='footerMain'>
            <h2>Aamuel Chua</h2>
            {/* <h3 style={{textAlign:'center'}}>An Aviator by passion | A Programmer by skill | An Extrovert by nature </h3> */}

            <div className='footerContent'>
                <div className='leftlink'>
                    <h4>Site Map</h4>
                    <Link to='/' className='footerlinks'>Main Page</Link>
                    <Link to='/experience#navBar' className='footerlinks'>Experience Page</Link>
                    <Link to='/project#navBar' className='footerlinks'>Projects Page</Link>
                    <Link to='/contact#navBar' className='footerlinks'>Contact Page</Link>
                </div>
                <div className='centerlink'>
                    <h4>Featured Links</h4>
                    <a href='https://www.ourcodelab.com/' className='footerlinks'>Start Up - OurCodeLab</a>
                    <a href='https://apps.apple.com/us/app/aviation-management/id1533061748' className='footerlinks'>Aviation Management App</a>
                </div>
                <div className='rightlink'>
                    <h4>Contact Me</h4>                
                    <a href='https://www.instagram.com/aamuelchua/' className='footerlinks'>Instagram - @aamuelchua</a>
                    <a href='https://www.linkedin.com/in/aamuelchua/' className='footerlinks'>LinkedIn</a>
                    <a href='ttps://github.com/aamuelchua' className='footerlinks'>GitHub</a>
                    <a href='mailto:aamuelchua@icloud.com' className='footerlinks'>Email</a>
                    <a href='resume.pdf' download className='footerlinks'>Download my Resume</a>
                </div>
                
            </div>
        </div>
    )
}
export default Footer;