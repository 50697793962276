import React,{useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import '../App.css';


const NavBar = () =>{
    const [showNavBar, setShowNavBar] = useState(false)
    const [showHamburger, setShowHamburger] = useState(false)
    const [showMainNavBar, setShowMainNavBar] = useState(true)
    const [fgColor, setFgColor] = useState('black')
    const [bgColor, setBgColor] = useState('white')

    window.onscroll = ()=>{
        if (window.scrollY > 100){
            setBgColor('black')
            setFgColor('white')
            setShowMainNavBar(true)
            console.log('show')
          }else{
            setShowNavBar(false)
            setBgColor('white')
            setFgColor('black')
          }
      }
    

    window.onresize = ()=>{
        console.log(window.innerWidth)
        if (window.innerWidth < 800){
            setShowHamburger(true)
            setShowNavBar(false)
        }else{
            setShowHamburger(false)
            setShowNavBar(false)
        }
    }

    const resizeManager = ()=>{
        if (window.innerWidth < 800){
            setShowHamburger(true)
            setShowNavBar(false)
        }else{
            setShowHamburger(false)
            setShowNavBar(false)
        }
    }

    useEffect(() => {
      resizeManager()
    }, [])
    

    return(
        <>
            {showMainNavBar?<div style={{backgroundColor:bgColor}} className="navbar" id='navBar'>
                <Link style={{color:fgColor,backgroundColor:bgColor}} className='headerLink' to={'/'}><h1>Aamuel Chua</h1></Link>
                {!showHamburger?<div className="navbarRight">
                    <Link style={{color:fgColor,backgroundColor:bgColor}} className='headerLink' to={'/experience'}><h2>Experience</h2></Link>
                    <Link style={{color:fgColor,backgroundColor:bgColor}} className='headerLink' to={'/project'}><h2>Projects</h2></Link>
                    <Link style={{color:fgColor,backgroundColor:bgColor}} className='headerLink' to={'/contact'}><h2>Contact Me</h2></Link>
                </div>:null}
                {showHamburger?<a onClick={()=>setShowNavBar(!showNavBar)}><img src={fgColor === "white"? "../img/ham-white.png":"../img/ham.png"} height={30} width={30} alt="" className='hamburger'/></a>:null}
                {showNavBar?
                <div style={{backgroundColor:bgColor}} className="mobileNavBar">
                    <Link style={{color:fgColor,backgroundColor:bgColor}} className='headerLink' to={'/experience'}><h2>Experience</h2></Link>
                    <Link style={{color:fgColor,backgroundColor:bgColor}} className='headerLink' to={'/project'}><h2>Projects</h2></Link>
                    <Link style={{color:fgColor,backgroundColor:bgColor}} className='headerLink' to={'/contact'}><h2>Contact Me</h2></Link>
                </div>
                :null}
            </div>:null}

            
        </>
    )
}
export default NavBar;