import '../App.css';
import React from 'react';
import NavBar from '../components/NavBar';
import { projects } from '../portfolio';
import { Link } from 'react-router-dom';
import { Circles } from '../components/Circles';
import Footer from '../components/Footer';

export default function ProjectPage() {
  
  return (
    <>
    <div className='App'>
      <NavBar/>
      <div className="window">
        <Circles/>
        <h1 className='headerTitle'>My Projects</h1>
        {/* personal */}
        <h3 className='subtitle'>Personal Projects</h3>
        <div className='projectFlatList'>
          {projects.map(project=>{
            if (project.location === "personal"){
              return(
                <Link className={'indyItemLink'} to={project.goto_link}>
                  <div className='indyItem'>
                    <img src={project.image_link} alt="" className='projectImage' style={{objectFit:"contain", height:"200px", width:"300px"}}/>
                    <div className='indyItemInnerContainer'>
                      <h2>{project.project_title}</h2>
                      <h4>{project.tags}</h4>
                      <p className="description">{project.description}</p>
                    </div>
                  </div>
                </Link>
              )
            }else{
              return null;
            }
            
          })}
        </div>

         {/* ocl */}
         <h3 className='subtitle'>OurCodeLab's Projects</h3>
        <div className='projectFlatList'>
          {projects.map(project=>{
            if (project.location === "ocl"){
              return(
                <Link className={'indyItemLink'} to={project.goto_link}>
                  <div className='indyItem'>
                    <img src={project.image_link} alt="" className='projectImage' style={{objectFit:"contain", height:"200px", width:"300px"}}/>
                    <div className='indyItemInnerContainer'>
                      <h2>{project.project_title}</h2>
                      <h4>{project.tags}</h4>
                      <p className="description">{project.description}</p>
                    </div>
                  </div>
                </Link>
              )
            }else{
              return null;
            }
          })}
        </div>

        {/* Empire Code */}
        <h3 className='subtitle'>Projects @ Empire Code</h3>
        <div className='projectFlatList'>
          {projects.map(project=>{
            if (project.location ==="empire"){
              return(
                <Link className={'indyItemLink'} to={project.goto_link}>
                  <div className='indyItem'>
                    <img src={project.image_link} alt="" className='projectImage' style={{objectFit:"contain", height:"200px", width:"300px"}}/>
                    <div className='indyItemInnerContainer'>
                      <h2>{project.project_title}</h2>
                      <h4>{project.tags}</h4>
                      <p className="description">{project.description}</p>
                    </div>
                  </div>
                </Link>
              )
            }else{
              return null;
            }
          })}
        </div>
      </div>
      
    </div>
    <Footer/>
    </>
  );
}